import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useToast } from 'hooks/useToast'
import { useCompanies } from './useCompanies'
import api from '../services/api'
import { formatDateBr } from '../utils/formatDate'

const NoticesContext = createContext<any>(null)

const NoticesProvider = ({ children }: PropsWithChildren) => {
  const formatDataEnd30 = () => {
    const today = new Date()
    const last30Days = new Date()
    last30Days.setDate(today.getDate() - 30) // Define a data de 30 dias atrás

    return last30Days
  }

  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [totalCountAll, setTotalCountAll] = useState(0)
  const [page, setPage] = useState(1)
  const { company } = useCompanies()
  const [emptyFilter, setEmptyFilter] = useState(false)
  const [startDate, setStartDate] = React.useState<Date | null>(formatDataEnd30())
  const [endDate, setEndDate] = React.useState<Date | null>(new Date())
  const [bulletin, setBulletins] = useState<any>([])
  const [totalPages, setTotalPages] = useState(0)
  const [loadingListEdit, setLoadingListEdit] = useState(false)
  const [bulletinResult, setBulletinResult] = useState<any>([])
  const [selectFilterBulletin, setSelectFilterBulletin] = useState<any>('todos')
  const [selectedListIdBulletin, setSelectedListIdBulletin] = useState<any>([])
  const [range_date_type, setRangeDateType] = useState('dispute_date')
  const [state, setState] = useState({
    id: 0,
    text: 'Todos Estados',
  })
  const [typeFilter, setTypeFilter] = useState({
    id: 1,
    text: 'Boletim Diário',
  })
  const [tags, setTags] = useState<string[]>([])
  const [selectedPortal, setSelectedPortal] = useState<any>({ id: 0, name: 'Todos os Portais' })
  const [modality, setModality] = useState<any>([{ id: 0, text: 'Todas as Modalidades' }])
  const [selectedModality, setSelectedModality] = useState<any>({ id: 0, text: 'Todas as Modalidades' })
  const [offset, setOffset] = useState(0)
  const [listSelectEdict, setListSelectEdict] = useState<any[]>([])
  const { toastError, toastSuccess } = useToast()
  const isFilter = !!tags.length || state.id !== 0 || selectedPortal.id !== 0

  const formatDataEnd15 = () => {
    const today = new Date()
    const last15Days = new Date()
    last15Days.setDate(today.getDate() - 13)
    setStartDate(last15Days)
    setEndDate(today)
  }

  useEffect(() => {
    if (typeFilter.id === 2) {
      formatDataEnd15()
    }
    setBulletinResult([])
    clearFilter()
  }, [typeFilter])

  const handleApiModality = async () => {
    try {
      const { data } = await api.get(`prospection/modalities`)

      const newModality = data
        .map((el: any) => {
          return { ...el, text: el.name }
        })
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
      setModality(() => [...modality, ...newModality])
    } catch (e: any) {
      toastError('Erro ao buscar modalidades', 'Tente novamente mais tarde.')
    }
  }

  const getLitBulletin = async () => {
    setLoadingListEdit(true)
    try {
      const {
        data: { bulletins, total },
      } = (await api.get(
        `prospection/bulletins/bydate/company/${company.id}?after_day=${formatDateBr(
          startDate
        )}&before_day=${formatDateBr(endDate)}`
      )) as any
      setTotalPages(total)
      setBulletins(bulletins)
      setSelectedListIdBulletin(bulletins.map((el: any) => el.id))
    } catch (e: any) {
      toastError('Erro ao buscar boletins', 'Tente novamente mais tarde.')
    } finally {
      setLoadingListEdit(false)
    }
  }

  const getLitBulletinResult = async () => {
    setLoading(true)
    setEmptyFilter(false)
    try {
      const {
        data: { bulletin_result, total },
      } = (await api.get(
        `prospection/bulletin-result-by-date/company/${company.id}?page=${page - 1}&after_day=${formatDateBr(
          startDate
        )}&before_day=${formatDateBr(endDate)}`,
        {
          params: {
            page: page - 1,
          },
        }
      )) as any
      setBulletinResult(bulletin_result)
      if (page === 1) {
        setTotalCount(total)
      }
    } catch (e: any) {
      toastError('Erro ao buscar boletins', 'Tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  const getBulletinById = async () => {
    if (isFilter) {
      getLitBulletinResultFilter()
      return
    }

    if (selectFilterBulletin === 'todos') {
      getLitBulletinResult()
    } else {
      setLoading(true)
      try {
        const {
          data: { bulletin_result, total },
        } = (await api.get(
          `prospection/result-by-bulletin/company/${company.id}/${selectFilterBulletin}?page=${page - 1}`
        )) as any
        setBulletinResult(bulletin_result)
        if (page === 1) {
          setTotalCount(total)
        }
      } catch (e: any) {
        toastError('Erro ao buscar editais', 'Tente novamente mais tarde.')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleParams = () => {
    const params = {
      bulletin_list: selectFilterBulletin === 'todos' ? selectedListIdBulletin.join(',') : selectFilterBulletin,
      uf: state.id === 0 ? '' : state.text,
      words: tags.length ? tags.join(',') : '',
      portal_name: selectedPortal.id === 0 ? '' : selectedPortal.name,
    }

    const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value))

    return filteredParams
  }
  const getLitBulletinResultFilter = async () => {
    setLoading(true)
    setEmptyFilter(false)
    try {
      const {
        data: { bulletin_result, total },
      } = (await api.get(`prospection/bulletin-result-with-filter/company/${company.id}?page=${page - 1}`, {
        params: handleParams(),
      })) as any
      setBulletinResult(bulletin_result)
      if (page === 1) {
        setTotalCount(total)
      }
    } catch (e: any) {
      toastError('Erro ao buscar editais', 'Tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  const handleParamsAvanced = () => {
    const params = {
      uf: state.id === 0 ? '' : state.text,
      words: tags.length ? tags.join(',') : '',
      portal: selectedPortal.id === 0 ? '' : selectedPortal.id,
      modality: selectedModality.id === 0 ? '' : selectedModality.id,
      range_date_type,
      offset,
    }

    const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value))

    return filteredParams
  }

  const getBulletinByFilterAvanced = async () => {
    setLoading(true)

    try {
      const {
        data: { edicts, offset },
      } = (await api.get(
        `prospection/edict/new/filtered-by?start_date=${formatDateBr(startDate)}&end_date=${formatDateBr(endDate)}`,
        {
          params: handleParamsAvanced(),
        }
      )) as any
      setBulletinResult(offset ? [...bulletinResult, ...edicts] : edicts)
      setOffset(offset)
    } catch (e: any) {
      toastError('Error ao buscar editais na pesquisa avançada', 'Tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  const handleParamsFavoritos = () => {
    return {
      uf: state.id === 0 ? '' : state.text,
      words: tags.length ? tags.join(',') : '',
      portal_name: selectedPortal.id === 0 ? '' : selectedPortal.name,
    }
  }

  const getBulletinByFilterFavorito = async () => {
    setLoading(true)
    setEmptyFilter(false)
    try {
      const {
        data: { bulletin_result, total },
      } = (await api.get(
        `prospection/bulletin-result-with-filter/favorited/company/${company.id}?dispute_date_after=${formatDateBr(
          startDate
        )}&dispute_date_before=${formatDateBr(endDate)}&page=${page - 1}`,
        {
          params: handleParamsFavoritos(),
        }
      )) as any
      setBulletinResult(bulletin_result)
      if (page === 1) {
        setTotalCount(total)
      }
    } catch (e: any) {
      toastError('Error ao buscar editais na pesquisa do favoritos', 'Tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  const isFilterBoletimDiario = typeFilter.id === 1
  const isFilterPesquisaAvancada = typeFilter.id === 2

  useEffect(() => {
    if (company?.id) {
      handleApiModality()
    }
  }, [company?.id])

  useEffect(() => {
    if (isFilterBoletimDiario && endDate && company?.id) {
      getLitBulletin()
    }
  }, [company?.id, endDate, typeFilter])

  useEffect(() => {
    if (company?.id) {
      getLitBulletinResult()
    }
  }, [bulletin])

  useEffect(() => {
    if (isFilterBoletimDiario && company?.id) {
      getBulletinById()
    }
  }, [selectFilterBulletin, page])

  const clearFilter = () => {
    setTags([])
    setOffset(0)
    setSelectedPortal({ id: 0, name: 'Todos os Portais' })
    setState({ id: 0, text: 'Todos Estados' })
    setSelectedModality({ id: 0, text: 'Todas as Modalidades' })
    setSelectFilterBulletin('todos')
    if (typeFilter.id === 2) {
      formatDataEnd15()
    } else {
      setStartDate(formatDataEnd30())
      setEndDate(new Date())
    }
  }

  const reseteFilter = () => {
    setBulletinResult([])
    setPage(1)
    setOffset(0)
  }

  const handleSetFavorite = async (action: string) => {
    const isFavorite = action === 'set'

    try {
      const { data } = (await api.post(`prospection/bulletin-result/set_unset/list/favotite`, {
        action,
        list_bulletin_result_pk: listSelectEdict,
      })) as any

      const newBulletinResult = bulletinResult.map((el: any) => {
        const isSelected = listSelectEdict.includes(el.id)
        return isSelected ? { ...el, is_favorite: isFavorite } : { ...el }
      })
      setBulletinResult(() => newBulletinResult)
      setListSelectEdict([])
      toastSuccess(isFavorite ? 'Editais favoritados com sucesso.' : 'Os editais foram desfavoritados com sucesso.', '')
    } catch (e) {
      toastError(
        isFavorite ? 'Erro ao favoritar edital' : 'Erro ao desfavoritados editais',
        'Tente novamente mais tarde.'
      )
      console.log(e)
    }
  }

  const handleSetFavoriteState = (id: number, isFavorite: boolean) => {
    const newBulletinResult = bulletinResult.map((el: any) => {
      return el.id === id ? { ...el, is_favorite: isFavorite } : { ...el }
    })
    setBulletinResult(() => newBulletinResult)
  }

  return (
    <NoticesContext.Provider
      value={{
        bulletin,
        totalPages,
        page,
        loading,
        totalCountAll,
        startDate,
        endDate,
        loadingListEdit,
        bulletinResult,
        selectFilterBulletin,
        setEndDate,
        setSelectFilterBulletin,
        getLitBulletinResultFilter,
        setStartDate,
        setPage,
        getLitBulletin,
        totalCount,
        state,
        setState,
        tags,
        setTags,
        selectedPortal,
        setSelectedPortal,
        typeFilter,
        setTypeFilter,
        modality,
        setModality,
        selectedModality,
        setSelectedModality,
        getBulletinByFilterAvanced,
        clearFilter,
        isFilterPesquisaAvancada,
        range_date_type,
        setRangeDateType,
        getBulletinByFilterFavorito,
        listSelectEdict,
        setListSelectEdict,
        handleSetFavorite,
        handleSetFavoriteState,
        reseteFilter,
      }}
    >
      {children}
    </NoticesContext.Provider>
  )
}

const useNotices = () => {
  const context = useContext(NoticesContext)
  if (!context) {
    throw new Error('useCompanies must be used within a NoticesProvider')
  }
  return context
}

export { NoticesProvider, useNotices }
